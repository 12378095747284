import React from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'

const Contact = () => {
  return (
    <div>
      <section id='contact' className='contact-section'>
        <h1>Kontak Saya</h1>
        <p>Ini adalah bagian kontak saya.</p>
      </section>
    </div>
  )
}

export default Contact
